export const languageCorr = [
"af","Afrikaans",
"ar-AE", "Arabic - United Arab Emirates",
"ar-SA", "Arabic - Saudi Arabia",
"ar-TN","Arabic - Tunisia",
"be","Belarusian",
"bg","Bulgarian",
"bs-BA","Bosnian",
"ca","Catalan",
"cs","Czech",
"sr-Cyrl-CS","Serbian - Cyrillic",
"uz-Cyrl-UZ","Uzbek - Cyrillic",
"da","Danish",
"de","German",
"el","Greek",
"Neutral","English",
"es","Spanish",
"es-AR","Spanish - Argentina",
"et","Estonian",
"eu-ES","Basque",
"fa","Farsi",
"fi","Finnish",
"fr","French",
"gl","Galician",
"he","Hebrew",
"hi","Hindi",
"hr","Croatian",
"hu","Hungarian",
"hy","Armenian",
"id","Indonesian",
"is","Icelandic",
"it","Italian",
"ja","Japanese",
"ka","Georgian",
"kk","Kazakh",
"ko","Korean",
"lo","Laotian",
"az-Latn-AZ","Azeri - Latin",
"lt","Lithuanian",
"sr-Latn-CS","Serbian - Latin",
"lv","Latvian",
"mk","Macedonian",
"mn", "Mongolian",
"ms-MY", "Malay",
"my-MM","Burmese",
"nb-NO","Norwegian - Bokmål",
"nl","Dutch",
"pl","Polish",
"pt-BR","Portuguese - Brazilian",
"pt","Portuguese",
"ro","Romanian",
"ru", "Russian",
"si", "Sinhala",
"sk","Slovak",
"sl","Slovenian",
"sq","Albanian",
"sv","Swedish",
"sw","Swahili",
"tg-Cyrl-TJ","Tajik",
"th","Thai",
"tr","Turkish",
"uk","Ukrainian",
"vi","Vietnamese",
"zh-CN","Chinese - Simplified",
"zh-TW","Chinese - Traditional"
];
const otherModules="Other modules";
const outOfDateNotice="";
export const comingSoonLabel="Translation is almost ready and <br />will appear with the nearest release";
const XmasGreetingText="<strong>Holiday greetings from ONLYOFFICE!</strong> We wish you every holiday happiness! Merry Christmas and Happy New Year!";
const valentineGreetingText="<strong>Happy St. Valentine\"s day from ONLYOFFICE!</strong> True love requires patience, trust, faithfulness and commitment to one another. ONLYOFFICE team loves you all!";
const tgivingGreetingText="";
const serverCommandsTranslation="All commands from the article in one place";
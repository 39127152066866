import styled from "styled-components";
import Section from "@components/common/section";

const StyledArticleContent = styled(Section)`
  .section-page {
    display: flex;
  }
`;

export default StyledArticleContent;
